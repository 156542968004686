import React from 'react';
import logo from './logo.svg';
import './App.css';
import MainBar from './components/MainBar';
import { Box, Container, Grid, Stack, Typography } from '@mui/material';

function App() {
  return (
    <React.Fragment>
      {/* <MainBar/> */}
      <Grid className='site-container'>
      <Box
          className='logo'
          component="img"
          src="/S.png"
      />
      <Box className="loading-box">
        <Typography className="site-under-construction">Site under construction</Typography>
        <Box
            className='loading'
            component="img"
            src="/Loading.png"
        />
      </Box>
      </Grid>
    </React.Fragment>
  );
}

export default App;
